<template>
 <el-dialog :visible.sync="visibleCode" width="340px" :title="title" top="25vh">
     <img :src="path" class="dialogCode"/>
 </el-dialog>
</template>
<script>
export default{
    data() {
         return{
             title:'手机版和电脑版同一个账号',
            path:'',//二维码路径
            visibleCode:false, //是否显示移动版二维码
         }
     }
}
</script>
<style lang="less" scoped>
  .dialogCode{
      width:100%;
 }
 /deep/ .el-dialog__body {
    padding: 0 20px 10px 20px;
}

</style>
